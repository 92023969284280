import { useRoute } from '#app'
import { computed } from 'vue'
import {
    layoutModeGroupLink,
    layoutModeKioskLink,
    layoutModeLightboxLink
} from '~/composables/routing'

export function useLayoutMode() {
    const route = useRoute()
    const isPrintMode = computed(
        () => typeof route.query.print === 'string' && route.query.print !== ''
    )
    const printMode = computed(() => route.query.print)

    const layoutMode = computed(() => route.params.mode)
    const isLightbox = computed(() => layoutMode.value === 'lightbox')
    const kioskSlug = computed(() => {
        return typeof layoutMode.value === 'string' &&
            layoutMode.value.startsWith('kiosk-')
            ? layoutMode.value.substring(6)
            : null
    })
    const isKiosk = computed(() => kioskSlug.value !== null)

    const groupBookingId = computed(() => {
        return typeof layoutMode.value === 'string' &&
            layoutMode.value.startsWith('group-')
            ? layoutMode.value.substring(6)
            : null
    })
    const isGroup = computed(() => groupBookingId.value !== null)

    function preserveModeLink(url: string) {
        if (isLightbox.value) {
            return layoutModeLightboxLink(url)
        }

        if (kioskSlug.value) {
            return layoutModeKioskLink(kioskSlug.value, url)
        }

        if (groupBookingId.value) {
            return layoutModeGroupLink(groupBookingId.value, url)
        }

        return url
    }

    return {
        isPrintMode,
        printMode,
        layoutMode,
        isLightbox,
        isKiosk,
        kioskSlug,
        isGroup,
        groupBookingId,
        preserveModeLink
    }
}
