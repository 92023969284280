import { useHead } from 'unhead'
import {
    computed,
    watch,
    ref,
    provide,
    inject,
    Ref,
    readonly,
    onMounted,
    onUnmounted
} from 'vue'
import { useI18n } from 'vue-i18n'

const RECAPTCHA_INJECTION_KEY = 'RECAPTCHA_INJECTION_KEY'

export function useRecaptchaReady(): Readonly<Ref<boolean>> {
    return inject(RECAPTCHA_INJECTION_KEY, ref(false))
}

export function createRecaptcha() {
    const { locale } = useI18n()
    const lang = computed(() => {
        return locale.value === 'es-ES' ? 'es' : undefined
    })
    // const url = computed(() => {
    //     return (
    //         'https://www.google.com/recaptcha/api.js?onload=RedpointRecaptchaLoad&render=explicit' +
    //         (lang.value !== undefined ? '&hl=' + lang.value : '')
    //     )
    // })
    const url = computed(() => {
        return 'https://www.google.com/recaptcha/api.js?onload=RedpointRecaptchaLoad&render=explicit'
    })

    const ready = ref(false)
    watch(
        url,
        () => {
            ready.value = false
            window.RedpointRecaptchaReady = false
        },
        { flush: 'sync' }
    )

    provide(RECAPTCHA_INJECTION_KEY, readonly(ready))

    onMounted(() => {
        if (window.RedpointRecaptchaReady) {
            ready.value = true
        }
        window.RedpointRecaptchaReadyFunction = () => {
            ready.value = true
        }
    })

    onUnmounted(() => {
        window.RedpointRecaptchaReadyFunction = undefined
    })

    useHead({
        script: () => [
            {
                innerHTML: `var RedpointRecaptchaLoad = function() {window.RedpointRecaptchaReady = true; if (typeof window.RedpointRecaptchaReadyFunction === 'function') { window.RedpointRecaptchaReadyFunction() } }`
            },
            {
                src: url.value,
                async: true,
                defer: true
            }
        ]
    })
}
